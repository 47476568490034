<template>
  <mdb-container>
    <mdb-col>
      <div class="mt-5 sub_content">
        <h3>시제품 의뢰 문의</h3>
      </div>
      <div class="my-3 card">
        <mdb-tbl reponsive>
          <mdb-tbl-head style="background-color: #ececec;">
            <tr>
              <th colspan="4" style="font-size: 20px;">{{ title }}</th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr>
              <th>등록일</th>
              <td>{{ reg_date }}</td>
              <th>분야</th>
              <td>{{ fields }}</td>
            </tr>
            <tr>
              <th>작성자</th>
              <td>{{ writer }}</td>
              <th>이메일</th>
              <td>{{ email }}</td>
            </tr>
            <tr>
              <th style="vertical-align: middle">파일</th>
              <td colspan="3">
                <ul>
                  <li v-if="file_name !== ''">
                    <a :href="file_name"> {{ file_url }}</a>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td colspan="4" style="text-align: center">
                <div v-if="image_url !== ''">
                  <img :src="image_url" alt="img" class="image_center">
                </div>
                <div class="my-3" v-if="contents !== ''">
                  {{ contents }}
                </div>
              </td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </div>
    </mdb-col>
    <mdb-col class="mb-5 text-right" style="text-align: center">
      <mdb-btn outline="danger" small icon="trash" @click="deletePost">삭제</mdb-btn>
    </mdb-col>
  </mdb-container>
</template>

<script>
import {mdbCol, mdbContainer, mdbTbl, mdbTblBody, mdbTblHead, mdbBtn} from 'mdbvue';
import firebase from "firebase/app";

export default {
  name: "RequestManageView",
  data() {
    return {
      id: this.$route.query.id,
      contents: '',
      files: [],
      image_url: '',
      reg_date: '',
      title: '',
      views: 0,
      views_count: 0,
      writer: '',
      reply_contents: '',
      reply_reg_date: '',
      reply_writer: '',
      replies: [],
      reply: '',
      fields: '',
      file_name: '',
      file_url: '',
      email: '',
    }
  },
  components: {
    mdbBtn,
    mdbContainer,
    mdbTblHead,
    mdbCol,
    mdbTbl,
    mdbTblBody
  },
  mounted() {
    this.onFirebaseLoadData();
  },
  methods: {
    onFirebaseLoadData() {
      const self = this;
      const db = firebase.firestore();
      const docRef = db.collection("requestBoard");

      // 본문을 가져와
      docRef.doc(self.id).get()
          .then(function (querySnapshot) {
            const _data = querySnapshot.data();
            self.contents = _data.contents;
            if (!self.isEmpty(_data.files)) {
              self.files = Object.entries(_data.files);
              self.file_url = Object.entries(_data.files)[0][0];
              self.file_name = Object.entries(_data.files)[0][1];
            }
            self.image_url = _data.image_url;
            const date = new Date(_data.reg_date.seconds * 1000);
            self.reg_date = self.getParseDate(date);
            self.title = _data.title;
            self.views = _data.views;
            self.writer = _data.writer;
            self.fields = _data.fields;
            self.email = _data.email;
          });


    },
    isEmpty(param) {
      return Object.keys(param).length === 0;
    },

    setWriteReply() {
      const self = this;
      const db = firebase.firestore();

      const data = {
        reply_contents: self.reply,
        reply_writer: self.reply_writer,
        uid: self.$store.state.firebase_uid,
        reply_reg_date: firebase.firestore.Timestamp.fromDate(new Date()),
      }

      const docRef = db.collection("requestBoard");

      docRef.doc(self.id).collection('replies').add(data)
          .then(() => {
            alert('댓글을 등록했습니다.');
            self.getRepliesData();
            self.reply_writer = '';
            self.reply = '';
          })
          .catch((err) => {
            console.log(err)
          })
    },

    /**
     * dataTime => 2000-01-01 10:30:20
     * @param date
     * @returns {string}
     */
    getParseDate(date) {
      return date.getFullYear() +
          "-" + (date.getMonth() + 1) +
          "-" + date.getDate() +
          " " + date.getHours() +
          ":" + date.getMinutes() +
          ":" + date.getSeconds()
    },
    deletePost() {
      const self = this;

      const confirmDialog = confirm('정말로 삭제하시겠습니까?');
      if (!confirmDialog) {
        return;
      }

      const db = firebase.firestore();
      const docRef = db.collection("requestBoard");
      docRef.doc(self.id)
          .delete()
          .then(() => {
            this.$router.replace('/admin/requestManageList').catch((err) => {
              console.log(err)
            })
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
    },
  }
}
</script>

<style scoped>

.sub_content {
  text-align: center;
}

.sub_content h3 {
  font-weight: bold;
}

th {
  background-color: #ececec;
}

.image_center {
  max-width: 100%;
  margin: 0 auto;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reply-form li {
  padding: 20px 16px;
  border-bottom: 1px solid #e3e5e8;
  list-style: none;
  font-size: 13px;
}

.member_info {
  margin-bottom: 10px;
}

</style>